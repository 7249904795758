import cmpstub from '@iabtcf/stub';
import { Routes, Route, Navigate } from 'react-router-dom';

import AppContextProvider from './context/AppContext';
import Global from './components/Global';
import Header from './components/Header';
import Radio from './routes/radio';
import Teaser from './routes/teaser';
// import Podcast from './routes/podcast';

if (typeof window !== 'undefined') {
  cmpstub();
}

function App() {
  return (
    <AppContextProvider>
      <Global />
      <Header />
      <Routes>
        <Route index element={<Radio />} />
        <Route path="teaser" element={<Teaser />} />
        {/* <Route path="podcast" element={<Podcast />} /> */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </AppContextProvider>
  );
}

export default App;
