import { useState, useEffect } from 'react';
import { ScreenSize } from '../utils/types';

const useScreenSize = (): ScreenSize => {
  const [screenSize, setScreenSize] = useState(ScreenSize.MOBILE);

  useEffect(() => {
    const mobileQuery = window.matchMedia('(max-width: 767px)');
    const tabletQuery = window.matchMedia(
      '(min-width: 768px) and (max-width: 1024px)',
    );
    const desktopQuery = window.matchMedia('(min-width: 1025px)');

    const updateScreenSize = () => {
      if (mobileQuery.matches) {
        setScreenSize(ScreenSize.MOBILE);
      } else if (tabletQuery.matches) {
        setScreenSize(ScreenSize.TABLET);
      } else if (desktopQuery.matches) {
        setScreenSize(ScreenSize.DESKTOP);
      }
    };

    // Initial check
    updateScreenSize();

    mobileQuery.addEventListener('change', updateScreenSize);
    tabletQuery.addEventListener('change', updateScreenSize);
    desktopQuery.addEventListener('change', updateScreenSize);

    return () => {
      mobileQuery.removeEventListener('change', updateScreenSize);
      tabletQuery.removeEventListener('change', updateScreenSize);
      desktopQuery.removeEventListener('change', updateScreenSize);
    };
  }, []);

  return screenSize;
};

export default useScreenSize;
