import { styled } from '@mui/material/styles';
import { FC } from 'react';
import Kickword from './Kickword';
import useCurrentTrackData from '../../../hooks/useCurrentTrackData';
import { Box } from '@mui/material';

const TeaserPlayerTitle: FC = () => {
  const { data, error, isLoading } = useCurrentTrackData();

  // TODO: could benefit from prettier loading and error display
  if (error) return <div>Failed to load</div>;
  if (isLoading) return <div>Loading...</div>;

  const currentTrack = data?.track.playing;

  return (
    <TeaserPlayerTitleContainer>
      <Kickword />
      <Box>
        <SongName>{currentTrack?.trackTitle}</SongName>
        <Author>{currentTrack?.trackArtist}</Author>
      </Box>
    </TeaserPlayerTitleContainer>
  );
};

export default TeaserPlayerTitle;

const TeaserPlayerTitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minWidth: 124,

  [theme.breakpoints.up('md')]: {
    gap: 14,
    maxWidth: '100%',
  },
}));

const SongName = styled('p')(({ theme }) => ({
  color: '#1A1A1A',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  fontSize: 20,
  fontWeight: 600,
  lineHeight: '24px',
  margin: 0,

  [theme.breakpoints.up('md')]: {
    fontSize: 24,
    lineHeight: '32px',
  },
}));

const Author = styled('p')(({ theme }) => ({
  color: '#4C4C4C',
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20px',
  margin: 0,

  [theme.breakpoints.up('md')]: {
    fontSize: 16,
    lineHeight: '24px',
  },
}));
