import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { useAppContext } from '../../context/AppContext';
import { SupportedLocales } from '../../utils/types';
import { trackDownloadApp } from '../../utils/gtm';

const DownloadAppButton: FC = () => {
  const { platform, language } = useAppContext();

  const handleDownloadClick = () => {
    try {
      trackDownloadApp({ platform, language });
    } catch (error) {
      console.error('Error tracking download app event:', error);
    }
  };

  const linkText =
    language === SupportedLocales.DE
      ? 'Jetzt auch als App!'
      : "Aussi dans l'app!";
  // TODO: get the real app store link
  const appLink = 'https://www.apple.com/app-store/';

  return (
    <DownloadAppButtonWrapper>
      <DownloadAppButtonLink
        href={appLink}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleDownloadClick}
      >
        {linkText}
      </DownloadAppButtonLink>
    </DownloadAppButtonWrapper>
  );
};

export default DownloadAppButton;

const DownloadAppButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-start',
    marginBottom: 18,
    paddingLeft: 12,
  },
}));

const DownloadAppButtonLink = styled('a')(() => ({
  display: 'flex',
  padding: '10px 24px',
  borderRadius: 4,
  backgroundColor: '#00E3C6',
  color: '#1A1A1A',
  fontSize: 14,
  fontWeight: 600,
  lineHeight: '20px',
  textAlign: 'center',
  textDecoration: 'none',
}));
