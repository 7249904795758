import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // Mobile
      sm: 600, // Small tablets
      md: 768, // Tablets and larger devices
      lg: 1024, // Larger tablets and desktops
      xl: 1440, // Large desktops
    },
  },
});

export default theme;
