import { Language } from './types';

const server = 'https://w202.dmd2services.com/api/v2/api.php?action=';

export const API = {
  currentTrack: `${server}live`,
  history: `${server}history`,
};

export const RadioChannels: Record<Language, string> = {
  de: '1',
  fr: '2',
};

export const LiveStreamsURLs: Record<Language, string> = {
  de: `https://20min.dmd2streaming.com/20minuten_radio_64.aac`,
  fr: `https://20min.dmd2streaming.com/20minuten_fr_radio_128.mp3`,
};

export const errorCheck = (data: any) => {
  if (data.status !== 200) {
    const error = new Error('An error occurred while fetching the data');
    throw error;
  }
};

export const globalStyles = {
  body: {
    backgroundColor: '#C929FF',
  },
  'html, body, #root': {
    height: '100%',
  },
};

export const backgroundImageStyles = {
  backgroundImage: `url('/img/goat-background.png')`,
  backgroundRepeat: 'repeat',
  backgroundSize: '72px 72px',
};

export const AdsAccountNames: Record<Language, string> = {
  de: 'de-20minuten.ch',
  fr: 'fr-20minutes.ch',
};
