import { FC } from 'react';
import { styled } from '@mui/material/styles';
import AlbumCover from './AlbumCover';
import TeaserPlayerTitle from './TeaserPlayerTitle';
import TeaserPlayButton from './TeaserPlayButton';

const TeaserPlayer: FC = () => {
  return (
    <TeaserPlayerContainer>
      <ImageAndTextWrapper>
        <AlbumCover />
        <TeaserPlayerTitle />
      </ImageAndTextWrapper>
      <TeaserPlayButton />
    </TeaserPlayerContainer>
  );
};

export default TeaserPlayer;

const TeaserPlayerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#F5F7FA',
  padding: 8,
  borderRadius: 8,
  gap: 8,
  position: 'absolute',
  bottom: 12,
  width: 'calc(100% - 24px)',

  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-between',
    padding: 16,
    gap: 16,
  },
}));

const ImageAndTextWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 8,
  maxWidth: 'calc(100% - 48px)',

  [theme.breakpoints.up('md')]: {
    gap: 16,
    maxWidth: '100%',
  },
}));
