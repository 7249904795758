import { Grid } from '@mui/material';

import History from '../components/History';
import PlayerData from '../components/player/PlayerData';
import PlayerControls from '../components/player/Controls';
import { getPXDecreasedOnIOS } from '../utils/styles';
import { backgroundImageStyles } from '../utils/const';

const Radio = () => {
  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        ...backgroundImageStyles,
      }}
    >
      <Grid
        container
        height={{
          xs: '100%',
          md: `${getPXDecreasedOnIOS(500)}`,
        }}
        maxWidth={1332}
        sx={{
          margin: {
            lg: '24px auto',
          },
        }}
        style={{ outline: '2px solid white' }}
      >
        <Grid
          item
          xs={12}
          md={5}
          height={{
            xs: getPXDecreasedOnIOS(300),
            md: getPXDecreasedOnIOS(500),
          }}
          padding={2}
          sx={{
            backgroundColor: '#00E3C6',
            zIndex: 1, // Allows shadow to be seen
            padding: {
              xs: 2,
              md: 1,
            },
          }}
          boxShadow={{
            xs: '0px 4px 6px rgba(45, 14, 77, 0.3)',
            md: '4px 0px 6px -2px rgba(45, 14, 77, 0.3)',
          }}
        >
          <PlayerData />
          <PlayerControls />
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          paddingLeft={1}
          paddingRight={1}
          height={{
            xs: `calc(100% - ${getPXDecreasedOnIOS(300)})`,
            md: getPXDecreasedOnIOS(500),
          }}
          sx={{
            backgroundColor: '#c929ff',
            overflowY: 'scroll',
          }}
        >
          <History />
        </Grid>
      </Grid>
    </div>
  );
};

export default Radio;
