import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { useAppContext } from '../../../context/AppContext';
import { getConfig } from '../../../utils/config';
import { trackLaunchPlayer } from '../../../utils/gtm';

const TeaserPlayButton: FC = () => {
  const { language, platform } = useAppContext();
  const config = getConfig(language);

  const playUrl = `${config.meta.canonicalUrl}/?language=${language}&platform=${platform}`;

  const handleLaunchClick = () => {
    try {
      trackLaunchPlayer({ platform, language });
    } catch (error) {
      console.error('Error tracking launch player event:', error);
    }
  };

  return (
    <TeaserPlayBtn
      href={playUrl}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleLaunchClick}
    >
      <img src="/img/teaser-play-icon.svg" alt="White play icon" />
    </TeaserPlayBtn>
  );
};

export default TeaserPlayButton;

const TeaserPlayBtn = styled('a')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 40,
  height: 40,
  padding: 4,
  borderRadius: '50%',
  backgroundColor: '#C929FF',
  outline: 0,
  border: 0,
  cursor: 'pointer',
  textDecoration: 'none',
}));
